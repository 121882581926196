import http from "@/utils/request";
// 筛选条件接口
export function messageFilterList(data) {
  return http({
    method: "get",
    url: "/priceMessage/messageList.nd",
    data
  });
}
  // 待办列表接口

  export function getdelegateList(data) {
    return http({
      method: "POST",
      url: "/priceMessage/delegateList.nd",
      data
    });
  }
  // 去签署调接口
  export function gotolink(data) {
    return http({
      method: "get",
      url: "/task/link.nd",
      params:data
    });
  }
  export function getFile(data) {
    return http({
      method: "get",
      url: "/task/downFile.nd?id="+data,
     
    });
  }
  
  // 同意驳回接口  
  export function updateConsultLog(data) {
    return http({
      method: "post",
      url: "/order/updateConsultLog.nd",
      data
    });
  }

  // 待办筛选条件  http://b2b-front-cis.devapps.hisense.com/comm/dict.nd?pid=14170681474
  export function getTodoFilterList() {
    return http({
      method: "post",
      url: "comm/dict.nd?pid=102000",
    });
  }

    // 注销
    export function logoffConfirm(data) {
        return http({
            contentType: "application/x-www-form-urlencoded",
            method: "post",
            data,
            url: "/account/cancelAccount.nd",
        });
    }