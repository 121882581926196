
import { getDetail } from './api'
import { updateConsultLog } from "./../api";
export default {

  data() {
    return {

      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/todoList",
          name: "todoList",
          title: "待办"
        },
        {
          path: "/center/tododetail/detail",
          name: "tododetail",
          title: "待办详情"
        }
      ],
      baseInfo: {},
      productInfo: [],
      onluyOneMessage: false,
      visibleAgree: false,
      visibledisAgree: false,
      message: '',
      notHome: '0',
      loadingl:false,
      agree:true
    };
  },

  mounted() {
    // this.agree = this.$route.query.agree
    if( this.$route.query.agree) {
      this.agree = false
    }
    if (this.$route.query.notHome == '1') {
      this.notHome = '0'
      this.getDetails()
    } else {
      this.notHome = '1'
      this.getDetails()
    }

  },
  methods: {
    getDetails() {
      getDetail(this.$route.query.id).then(res => {
        this.baseInfo = res.data.orderHeader
        this.productInfo = res.data.orderLines
      })
    },
    goback() {
      window.close()
    },
    // 同意
    agreeA() {
  
      this.loadingl = true
      updateConsultLog({ id: this.$route.query.id, status: 'agree' }).then(res => {
        if (res.data.code == 200) {
          this.notHome = '0'
          this.onluyOneMessage = true
          this.message = res.data.content
          window.opener.location.reload();
          setTimeout(() => {
            window.close()

          }, 3000);
        } else {
          this.$message.warning(res.data.msg)
        }
        this.loadingl = false
        this.getDetails()
      }).catch(error => {
        console.log(error)
      })

    },
    // 不同意
    reject() {
      this.loadingl = true
      updateConsultLog({ id: this.$route.query.id, status: 'disagree' }).then(res => {
        if (res.data.code == 200) {
          this.onluyOneMessage = true
          this.notHome = '0'
          this.message = res.data.content
          this.loadingl = false
          window.opener.location.reload();
          setTimeout(() => {
            window.close()
          }, 3000);
        } else {
          this.$message.warning(res.data.msg)
        }
        this.getDetails()
      }).catch(error => {
        console.log(error)
      })

    },
  }
}


